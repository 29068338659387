
// import SkeletonTable from "../skeleton/skeleton-table";
import PressReleasesTable from "./press-releases-table.vue";

export default {
    name: "PressReleasesCard",
    components: {PressReleasesTable},
    props: {
        userId: {
            type: Number,
            default: null
        },
        companiesHash: {
            type: String,
            default: null,
        },
        companyIds: {
            type: Array,
            default: () => []
        },
        symbols: {
            type: Array,
            default: () => []
        },
        type: {
            type: String,
            default: "press-releases",
            validator(val) {
                return ["press-releases", "media-coverage", "all"].includes(val);
            }
        },
        pageSize: {
            type: Number,
            default: 5,
        },
    },
    data() {
        return {
            initial: true,
        }
    },
    computed: {
        option() {
            switch (this.type) {
                case "all":
                    return 0;
                case "media-coverage":
                    return 2;
                case "press-releases":
                default:
                    return 1;
            }
        },
    },
    watch: {},
    methods: {
    }

}
