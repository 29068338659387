
import LoadingSpinner from "../../loading-spinner.vue";

export default {
    name: "AutosaveCellBool",
    components: {LoadingSpinner},
    props: {
        initialValue: {
            type: Boolean,
            required: false
        },
        ownerId: {
            type: Number,
            required: true
        },
        attribute: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            value: null,
            allowUpdates: false,
            loading: false,
        }
    },
    watch: {},
    async created() {
        this.value = this.initialValue;
        await this.$nextTick();
        this.allowUpdates = true;
    },
    methods: {
        submitUpdate() {
            if (!this.allowUpdates) {
                return;
            }

            this.loading = true;
            this.$axios.patch(`/api/targeting_group_members/${this.ownerId}/update_attribute`, {
                attribute: this.attribute,
                value: this.value
            }, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            }).catch(() => {
                this.$emit("error");
            }).finally(() => {
                this.loading = false;
            });
        }
    },
}
