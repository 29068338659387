
import moment from "moment";
import SaveButton from "../save-button.vue";
import TableActions from "../table-actions.vue";

export default {
    name: "StockAlertModal",
    components: {TableActions, SaveButton},
    props: {
        company: {
            type: Object,
            required: true,
        },
        stockInfo: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            price: 0.00,
            details: "",
            tableConfig: {
                columns: [
                    {
                        headline: "Price",
                        classes: "fw-bold"
                    }, {
                        headline: "Details",
                        sticky: true,
                        sort: false,
                        classes: "render-newline"
                    }, {
                        headline: "Sent",
                    }, {
                        headline: "Sent at",
                        breakpoint: "all",
                    }, {
                        headline: "Created at",
                        breakpoint: "all",
                    }, {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                    }
                ],
            },
            stockAlerts: [],
            tableLoading: false,
            submitting: false,
            collapseShown: false,
            stockAlertToEdit: null,
            showSent: false,
        };
    },
    computed: {
        rows() {
            return this.stockAlerts.map((stockAlert) => {
                return [
                    {
                        text: this.formatCurrency(stockAlert.price, this.stockInfo.profile.currency),
                        sortValue: stockAlert.price,
                    },
                    {
                        text: stockAlert.details,
                        sticky: true,
                    },
                    {
                        type: "sent",
                        sent: stockAlert.sentAt !== null,
                        sortValue: stockAlert.sentAt !== null ? 1 : 0,
                    },
                    {
                        text: stockAlert.sentAt ? moment(stockAlert.sentAt).format("MM/DD/YYYY h:mm:ss a") : "",
                    },
                    {
                        text: moment(stockAlert.createdAt).format("MM/DD/YYYY h:mm:ss a"),
                    },
                    {
                        type: "actions",
                        stockAlert,
                    }
                ];
            })
        }
    },
    watch: {
        stockInfo: {
            handler() {
                this.price = (this.stockInfo.profile.price * 1.1).toFixed(2);
            },
            immediate: true
        },
        showSent() {
            this.loadStockAlerts();
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
            this.loadStockAlerts();
        });
    },
    methods: {
        closeModal() {
            this.$emit("closed");
        },
        submitForm() {
            this.submitting = true;

            if (this.stockAlertToEdit) {
                this.$axios.patch(`/api/stock_alerts/${this.stockAlertToEdit.id}`, {
                    price: parseFloat(parseFloat(this.price).toFixed(2)),
                    details: this.details,
                }, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                })
                    .then(() => {
                        this.resetForm();
                        this.$refs.addStockAlertCollapse.click();
                        this.loadStockAlerts();
                    })
                    .finally(() => {
                        this.submitting = false;
                    })
            } else {
                this.$axios.post("/api/stock_alerts", {
                    companyId: this.company.id,
                    price: parseFloat(parseFloat(this.price).toFixed(2)),
                    details: this.details,
                })
                    .then(() => {
                        this.resetForm();
                        this.$refs.addStockAlertCollapse.click();
                        this.loadStockAlerts();
                    })
                    .finally(() => {
                        this.submitting = false;
                    })
            }
        },
        loadStockAlerts() {
            this.tableLoading = true;
            let url = `/api/stock_alerts?company=${this.company.id}`;
            if (!this.showSent) {
                url += "&exists[sentAt]=0";
            }

            this.$axios.get(url)
                .then((response) => {
                    this.stockAlerts = response.data['hydra:member'];
                })
                .finally(() => {
                    this.tableLoading = false;
                })
        },
        deleteStockAlert(stockAlert) {
            this.$axios.delete(`/api/stock_alerts/${stockAlert.id}`)
                .then((response) => {
                    this.loadStockAlerts();
                });
        },
        editStockAlert(stockAlert) {
            this.stockAlertToEdit = stockAlert;
            this.price = this.stockAlertToEdit.price;
            this.details = this.stockAlertToEdit.details;

            if (!this.collapseShown) {
                this.$refs.addStockAlertCollapse.click();
            }
        },
        cancelEdit() {
            this.resetForm();

            if (this.collapseShown) {
                this.$refs.addStockAlertCollapse.click();
            }
        },
        resetForm() {
            this.stockAlertToEdit = null;
            this.price = (this.stockInfo.profile.price * 1.1).toFixed(2);
            this.details = "";
        }
    },
}
