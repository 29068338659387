
import moment from "moment";
import LoadingSpinner from "../loading-spinner";
import TableActions from "../table-actions";
import PercentageChange from "../helpers/percentage-change";
import modals from "../../mixins/modals";
import loadWhenVisible from "../../mixins/loadWhenVisible";
import {OPTION_COMPANY} from "../../mixins/company-classification";
import DownloadPeerGroupModal from "./download-peer-group-modal";
import PeersList from "./peers-list";
import optionButtonTooltip from "~/mixins/option-button-tooltip";

export default {
    name: "PeerGroup",
    components: {
        PeersList,
        PercentageChange,
        TableActions,
        LoadingSpinner,
        DownloadPeerGroupModal,
    },
    mixins: [optionButtonTooltip, modals, loadWhenVisible],
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            downloadModal: null,
            coverageModal: null,
            loading: false,
            rowsUpdated: moment().unix(),
            companyWorkingCopy: {},
            peerData: [],
            bankerPeerGroupCompanies: [],
            bankerPeerGroupData: [],
            analystPeerGroupCompanies: [],
            analystPeerGroupData: [],
            automaticPeerGroupsConfig: {
                columns: [
                    {
                        headline: "Symbol",
                        sort: true
                    }, {
                        headline: "Name",
                        sort: true,
                    }, {
                        headline: "HQ",
                        breakpoint: "xl",
                    }, {
                        headline: "Mkt Cap",
                        sort: true,
                        align: "end"
                    }, {
                        headline: "Cash",
                        title: "Cash and short term investments",
                        sort: true,
                        align: "end",
                        breakpoint: "xl",
                    }, {
                        headline: "Debt",
                        title: "Total Debt",
                        sort: true,
                        align: "end",
                        breakpoint: "xl",
                    }, {
                        headline: "Price",
                        sort: true,
                        align: "end",
                        breakpoint: "xl",
                    }, {
                        headline: "% Chg",
                        sort: true,
                        align: "end",
                        breakpoint: "xl",
                    }, {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                    },
                ],
                pagination: 10
            },
            fetchCompaniesRequest: null,
            fetchCompaniesRequests: [],
            loadingAutomaticPeers: false,
            loadingManualPeers: false,
            updated: moment().unix(),
            peerGroupType: 'manual',
            downloadKey: this.generateUUID(),
            OPTION_COMPANY
        }
    },
    computed: {
        isCompany() {
            return this.company.classification === OPTION_COMPANY;
        },
        automaticPeerRows() {

            return this.peerData.map(item => {
                let cash = null;
                let debt = null;
                let balanceSheetCurrency = "";
                let balanceSheetDate = "";

                if (item.mostRecentBalanceSheet) {
                    balanceSheetDate = this.formatDate(item.mostRecentBalanceSheet.date);
                    if (item.mostRecentBalanceSheet.reportedCurrency) {
                        balanceSheetCurrency = item.mostRecentBalanceSheet.reportedCurrency
                    }
                    if (item.mostRecentBalanceSheet.cashAndShortTermInvestments) {
                        cash = item.mostRecentBalanceSheet.cashAndShortTermInvestments
                    }
                    if (item.mostRecentBalanceSheet.totalDebt) {
                        debt = item.mostRecentBalanceSheet.totalDebt
                    }
                }

                return {
                    classes: item.id === this.company.id ? "selected-company" : "",
                    cells: [
                        {
                            text: item.symbol
                        },
                        {
                            type: 'name',
                            item,
                            sortValue: item.name
                        },
                        {
                            text: [item.city, item.state, item.country].filter(el => el && el.length > 0).join(", ")
                        },
                        {
                            text: this.nFormatCurrency(item.currency, item.marketCap, 2, false, 21, "M"),
                            sortValue: item.marketCap,
                        },
                        {
                            html: cash ? this.nFormatCurrency(balanceSheetCurrency, cash) + "<br/><span class='text-muted'>(" + balanceSheetDate + ")</span>" : "",
                            sortValue: cash,
                        },
                        {
                            html: debt ? this.nFormatCurrency(balanceSheetCurrency, debt) + "<br/><span class='text-muted'>(" + balanceSheetDate + ")</span>" : "",
                            sortValue: debt,
                        },
                        {
                            text: this.formatCurrency(item.price, item.currency),
                            classes: parseFloat(item.changesPercentage) >= 0 ? "text-success" : "text-danger",
                        },
                        {
                            text: isNaN(parseFloat(item.changesPercentage)) ? "" : String(parseFloat(item.changesPercentage).toFixed(2)) + "%",
                            sortValue: item.changesPercentage ? parseFloat(item.changesPercentage) : 0,
                            classes: parseFloat(item.changesPercentage) >= 0 ? "text-success" : "text-danger",
                        },
                        {
                            item,
                            type: "add-to-manual",
                            hash: this.createSimpleHash(item.name),
                        },
                    ]
                }
            });
        },
        updateUrl() {
            return '/api/companies/' + this.companyWorkingCopy.id;
        },
        showExport() {
            // console.log("showExport", {a: this.loadingAutomaticPeers, b: this.loadingManualPeers});
            return (!this.loadingAutomaticPeers) && (!this.loadingManualPeers);
        },

    },
    watch: {
        loadDataTrigger(value) {
            if (value) {
                if (this.company.ticker) {

                    this.loadAutomaticPeerGroups();
                }
                this.resetWorkingCopy();
                this.loadManualPeerGroups();
            }
        },
        peerData() {
            this.$nextTick(() => {
                this.rowsUpdated = moment().unix();
            })
        },
        rowsUpdated() {
            setTimeout(() => this.initializeOptionButtonTooltips('rows updated'), 250);
        },
        company: {
            handler() {
                // If a ticker was added to the company we have to load the automatich peer group
                if (this.company.ticker) {
                    this.loadAutomaticPeerGroups();
                }
            },
            deep: true,
        }
    },
    methods: {
        addToManualPeerGroup(symbolOrId) {
            this.loading = true;
            this.$axios.post("/api/companies/" + this.company.id + "/add_peer_group/" + symbolOrId).then((response) => {
                this.loadManualPeerGroups();
                if (this.company.ticker) {
                    this.loadAutomaticPeerGroups();
                }
                this.loading = false;

                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    message: response.data.feedback,
                    title: `Manual Peer Group`,
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
            })
        },
        resetWorkingCopy() {
            this.companyWorkingCopy = Object.assign({}, this.company);
        },
        loadAutomaticPeerGroups() {
            this.loadingAutomaticPeers = true;
            this.$axios.get("/api/companies/stock_info/" + this.company.id + "/stock_peers/")
                .then((response) => {
                    this.peerData = response.data;
                }).finally(() => {
                    this.loadingAutomaticPeers = false;
                // console.log("Loading automatic peers?", this.loadingAutomaticPeers);
                // console.log("Loading manual peers?", this.loadingManualPeers);
                // console.log("Show export", this.showExport);
                });
        },
        loadManualPeerGroups() {
            this.loadingManualPeers = true;
            this.$axios.get("/api/companies/peer_group/" + this.company.id)
                .then((response) => {
                    this.bankerPeerGroupCompanies = response.data.bankerCompanies;
                    this.bankerPeerGroupData = response.data.bankerPeerStockData;
                    this.analystPeerGroupCompanies = response.data.analystCompanies;
                    this.analystPeerGroupData = response.data.analystPeerStockData;
                    this.updated = moment().unix();
                })
                .finally(() => {
                    this.loadingManualPeers = false;
                });
        },
        updatePeerGroup(data) {
            const peerGroup = data.peerGroup;
            const perspective = data.perspective;
            const peerIDs = peerGroup.map((el) => el.id);
            this.$axios.post("/api/companies/" + this.company.id + "/update_peer_group", {
                peerIDs,
                perspective,
            }).then(() => {
                this.loadManualPeerGroups();
            });
        },
        downloadPeerGroups(type) {
            this.peerGroupType = type;
            this.$nextTick(() => {
                this.downloadModal = this.openModal(this.$refs.downloadPeerGroupModal);
            });
        },
        updateDownloadKey() {
            this.downloadKey = this.generateUUID();
        }
    }
}
