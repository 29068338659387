

import moment from "moment";
import SaveButton from "../save-button.vue";
import TableActions from "../table-actions.vue";

export default {
    name: "NdaModal",
    components: {TableActions, SaveButton},
    props: {
        company: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            description: null,
            files: null,
            ndaFile: null,
            start: null,
            end: null,
            collapseShown: false,
            ndas: [],
            config: {
                columns: [
                    {
                        headline: "Start",
                    },
                    {
                        headline: "End",
                    },
                    {
                        headline: "Description",
                        breakpoint: "xl"
                    },
                    {
                        headline: "File",
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                    },
                ],
                pagination: 10
            },
            tableLoading: false,
            dataUpdated: false,
            formKey: "ndaForm"
        }
    },
    computed: {
        rows() {
            return this.ndas.map((nda) => {
                return [
                    {
                        text: nda.start ? moment(nda.start).format('M/D/Y') : '',
                    },
                    {
                        text: nda.end ? moment(nda.end).format('M/D/Y') : '',
                    },
                    {
                        text: nda.description
                    },
                    {
                        type: 'file',
                        fileUrl: nda.companyFile ? nda.companyFile.fileUrl : null,
                    },
                    {
                        type: 'actions',
                        ndaId: nda.id,
                    }
                ];
            });
        }
    },
    watch: {
        files(files) {
            this.ndaFile = null;
            if (files && files.files.length > 0) {
                const reader = new FileReader();
                reader.readAsDataURL(files.files[0].file);
                reader.onload = () => {
                    this.ndaFile = {
                        filename: files.files[0].file.name,
                        data: reader.result
                    };
                };
            }
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', this.loadNDAs);
        this.$refs.modal.addEventListener('hidden.bs.modal', this.emitCloseEvent);
    },
    beforeDestroy() {
        this.$refs.modal.removeEventListener('show.bs.modal', this.loadNDAs);
        this.$refs.modal.removeEventListener('hidden.bs.modal', this.emitCloseEvent);
    },
    methods: {
        submitNda() {
            this.loading = true;
            this.$axios.post(`/api/nda`,
                             {
                                 companyId: this.company.id,
                                 start: this.start,
                                 end: this.end,
                                 description: this.description,
                                 ndaFile: this.ndaFile
                             })
                .then((response) => {
                    // Reset Form
                    this.description = null;
                    this.files = null;
                    this.ndaFile = null;
                    this.start = null;
                    this.end = null;
                    this.formKey = this.generateUUID();

                    this.loadNDAs();
                })
                .finally(() => {
                    this.loading = false;
                    this.dataUpdated = true;
                });
        },
        loadNDAs() {
            this.tableLoading = true;
            this.$axios.get('/api/ndas?company=' + this.company.id)
                .then((response) => {
                    this.ndas = response.data['hydra:member'];
                })
                .finally(() => {
                    this.tableLoading = false;
                })
        },
        deleteNda(ndaId) {
            this.$axios.delete('/api/ndas/' + ndaId)
                .then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: 'success',
                        title: 'Success',
                        message: 'NDA has been deleted.',
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                }).finally(() => {
                    this.loadNDAs();
                    this.dataUpdated = true;
                });
        },
        emitCloseEvent() {
            if (this.dataUpdated) {
                this.$emit("updated");
            } else {
                this.$emit("canceled");
            }
        }
    }
}
