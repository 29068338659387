

import SaveButton from "../../save-button.vue";
import contactBulkOperationForm from "../../contacts/contact-bulk-operation-form.vue";
import {TARGETING_HCW_STATUS_OPTIONS} from "../../../mixins/targeting-hcw-status";

export default {
    name: "TargetingGroupMemberBulkModal",
    components: {contactBulkOperationForm, SaveButton},
    mixins: [],
    props: {
        bulkContacts: {
            type: Object,
            required: true,
        },
        bulkMembers: {
            type: Object,
            required: true,
        },
        editingAccess: {
            type: Boolean,
            required: false,
            default: false,
        },
        targetingGroupId: {
            type: Number
        }
    },
    data() {
        return {
            operation: null,
            bulkOptions: [
                {
                    label: "Edit",
                    value: "edit"
                },
                {
                    label: "Delete",
                    value: "delete"
                }
            ],
            options: {
                hcwAdditionalNames: {
                    label: "HCW Additional Names",
                    value: null,
                    type: "boolean"
                },
                onCompanyMetWithList: {
                    label: "On Company Met With List",
                    value: null,
                    type: "boolean"
                },
                onCompanyTargetList: {
                    label: "On Company Target List",
                    value: null,
                    type: "boolean"
                },
                manualShareholder: {
                    label: "Shareholder Manual",
                    value: null,
                    type: "boolean"
                },
                doNotContact: {
                    label: "Do Not Contact",
                    value: null,
                    type: "boolean"
                },
                hcwStatus: {
                    label: "HCW Status",
                    value: null,
                    type: "select",
                    options: TARGETING_HCW_STATUS_OPTIONS
                },
                hcwComments: {
                    label: "HCW Comments",
                    value: null,
                    type: "text"
                },
                companyComments: {
                    label: "Company Comments",
                    value: null,
                    type: "text"
                },
                cdaSigned: {
                    label: "CDA Signed",
                    value: null,
                    type: "boolean"
                },
                dataRoomAccessGiven: {
                    label: "Data Room Access Given",
                    value: null,
                    type: "date"
                },
                dataRoomAccessed: {
                    label: "Data Room Accessed",
                    value: null,
                    type: "date"
                },
                feedback: {
                    label: "Feedback",
                    value: null,
                    type: "text"
                },
                other: {
                    label: "Other",
                    value: null,
                    type: "text"
                },
            },
            loading: false,
            selectedValues: {
                hcwAdditionalNames: null,
                onCompanyMetWithList: null,
                onCompanyTargetList: null,
                shareholderManual: null,
                doNotContact: null,
                hcwStatus: null,
                hcwComments: null,
                companyComments: null,
                cdaSigned: null,
                dataRoomAccessGiven: null,
                dataRoomAccessed: null,
                feedback: null,
                other: null,
            },
            bulkType: 'tgMembers'
        }
    },
    watch: {},
    created() {
        if(!this.editingAccess) {
            this.bulkType = 'contacts';
        }

        console.log("Bulk Contacts", this.bulkContacts);
    },
    methods: {
        submitForm() {
            let message = "Are you sure you want to continue?"
            switch (this.operation) {
                case "edit":
                    message = "Are you sure you want to update these records? Current values will be overwritten.";
                    break;
                case "delete":
                    message = "Are you sure you want to delete these records?";
                    break;
                default:
                    break;
            }
            if (!confirm(message)) {
                return;
            }

            this.loading = true;
            this.$axios.post(`/api/targeting_group_members/bulk/${this.targetingGroupId}`, {
                hash: this.bulkMembers.hash,
                editValues: this.selectedValues,
                operation: this.operation
            })
                .then((response) => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Success",
                        message: response.data.message,
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                })
                .catch((error) => {
                    console.error("Error", error);
                })
                .finally(() => {
                    this.loading = false;
                    this.submitted();
                })
        },
        submitted() {
            this.$emit("submitted");
        },
        exportAsExcel() {
            this.$emit('export-as-excel', {hash: this.bulkContacts.hash});
        },
    }
}
