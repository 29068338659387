
import moment from "moment";

export default {
    name: "SelectCompanies",
    props: {
        value: {
            type: Array,
            required: true,
        },
        hideWithoutEmail: {
            type: Boolean,
            default: false,
        },
        forCompanyGroup: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedRows: [],
            preSelectedRowIds: [],
            preSelectedRows: [],
            ajaxRows: [],
            ajaxAll: 0,
            searchQuery: "",
            mode: "none",
            pageChanged: false,
            modeUpdated: moment().unix(),
            addReason: false,
            reason: null,
        }
    },

    computed: {
        realSelectedCompanies() {
            switch (this.mode) {
                case "none":
                    return 0;
                // case "filtered":
                //     return this.ajaxAll;
                case "page":
                    return this.ajaxRows.length;
                case "selected":
                    return this.preSelectedRowIds.length;
                default:
                    return 0
            }
        },
        ajaxConfig() {
            return {
                // dummy columns, otherwise warning
                columns: [
                    {
                        headline: "Name",
                    }, {
                        headline: "Ticker",
                    }, {
                        headline: "Mkt&nbsp;Cap",
                        hidden: true,
                    }, {
                        headline: "Price",
                        hidden: true,
                    }, {
                        headline: "%&nbsp;Chg",
                        hidden: true,
                    }, {
                        headline: "$&nbsp;Chg",
                        hidden: true,
                    }, {
                        headline: 'Event Role',
                        hidden: true,
                    }, {
                        headline: 'Meetings',
                        hidden: true,
                    }, {
                        headline: 'Participants',
                        hidden: true,
                    }, {
                        headline: "Sector, Industry",
                        hidden: true,
                    }, {
                        headline: "City,&nbsp;State",
                        hidden: true,
                    }, {
                        headline: "Country",
                        hidden: true,
                    }, {
                        headline: "Last Deal",
                        hidden: true,
                    }, {
                        headline: "My Last Meeting",
                        hidden: true,
                    }, {
                        headline: "Relationship",
                        hidden: true,
                    }, {
                        headline: "Lead&nbsp;Banker",
                        hidden: true,
                    } , {
                        headline: "Banker",
                        hidden: true,
                    } , {
                        headline: "Coverage&nbsp;Analyst",
                        hidden: true,
                    }, {
                        headline: "Market List Members",
                        hidden: true,
                    }, {
                        headline: 'Feedback',
                        hidden: true,
                    }, {
                        headline: 'Agenda',
                        hidden: true,
                    }, {
                        headline: 'Portrait',
                        hidden: true,
                    }, {
                        headline: 'Research Report',
                        hidden: true,
                    },{
                        headline: "Actions",
                        hidden: true,
                    }

                ],
                pagination: 10,
                search: true,
                select: true,
                selectPosition: "pre",
                selectAll: true,
                prettySelect: true,
                pageSortSelect: true,
                emptyPlaceholder: "No Companies...",
                ajaxUrl: "/api/companies/list",
                disallowAllOption: true,
            }
        },
        realSelectedRows() {
            let ids = [];

            switch (this.mode) {
                // case "filtered":
                //
                //     ids = {
                //         all: this.ajaxAll,
                //         filters: this.filters,
                //         query: this.searchQuery,
                //     };
                //     break;
                case "selected":
                    ids = this.preSelectedRowIds;
                    break;
                case "page":
                    ids = this.ajaxRows.map(function (row) {
                        return row.company_id;
                    });
                    break;
                case "none":
                default:
                    // do nothing
                    break;
            }

            return ids;
        },
        filters() {
            return {
            };
        },
    },

    watch: {
        selectedRows(rows) {

            if (rows.length) {
                this.mode = "selected"
            }

            if (!this.pageChanged) {

                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedRowIds.includes(rows[i].company_id)) {
                        this.preSelectedRowIds.push(rows[i].company_id);
                    }
                }

                for (let j = 0; j < this.ajaxRows.length; j++) {
                    const id = this.ajaxRows[j].company_id;

                    const index = this.preSelectedRowIds.indexOf(id);

                    if (!rows.map(r => r.company_id).includes(id) && index !== -1) {
                        this.preSelectedRowIds.splice(index, 1);
                    }
                }
            }

        },
        mode(val, old) {
            if (val !== "selected" && old === "selected" && (this.selectedRows.length || this.preSelectedRowIds.length)) {
                this.selectedRows = [];
                this.preSelectedRowIds = [];
                this.modeUpdated = moment().unix()
            }

        },
        realSelectedRows: {
            handler(val) {
                this.$emit("input", val)
            },
            deep: true
        },
        reason: {
            handler(val) {
                this.$emit("reason", val)
            }
        }
    },
    methods: {
        onPageChange() {
            this.pageChanged = true;
        },


        onQueryUpdate(query) {
            this.searchQuery = query;
        },

        onUpdate(data) {
            this.pageChanged = false;
            if (data && data.rows && data.rows.length) {
                this.$nextTick(() => {

                    this.preSelectedRows = this.preSelectedRowIds.map(id => {
                        return {
                            key: "company_id",
                            value: id
                        }
                    });

                });
            }
            this.ajaxRows = data.rows;
            this.ajaxAll = data.all;

        },
        clearPreSelection() {
            this.preSelectedRowIds = [];
            this.preSelectedRows = [];
        },

    }
}
