
import moment from "moment";

export default {
    name: "PressReleasesTable",
    components: {},
    props: {
        userId: {
            type: Number,
            default: null
        },
        companiesHash: {
            type: String,
            default: null,
        },
        companyIds: {
            type: Array,
            default: () => []
        },
        symbols: {
            type: Array,
            default: () => []
        },
        type: {
            type: String,
            default: "press-releases",
            validator(val) {
                return ["press-releases", "media-coverage", "all"].includes(val);
            }
        },
        pageSize: {
            type: Number,
            default: 5,
        },
        saOnly: {
            type: Boolean,
            default: false,
        },
        select: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            updated: null,
            sortOrder: [{
                index: 0,
                asc: false,
            }],
            key: moment().unix(),
            filters: {
                relevantForUserId: this.userId,
                companyIds: this.companyIds,
                companiesHash: this.companiesHash,
                symbols: this.symbols,
                saOnly: this.saOnly,
            },
            selectedRows: [],
        }
    },
    computed: {
        option() {
            switch (this.type) {
                case "all":
                    return 0;
                case "media-coverage":
                    return 2;
                case "press-releases":
                default:
                    return 1;
            }
        },
        config() {
            return {
                columns: [
                    {
                        headline: "Date",
                        sort: !this.select
                    }, {
                        headline: "Symbol",
                        hidden: !(this.userId > 0) || this.symbols.length === 1,
                    },
                    {
                        headline: "Title",
                        sticky: true,
                        hideHeadlineBreakpoint: "all",
                    },
                    {
                        headline: "Text",
                        breakpoint: "all",
                        align: "start",
                    }, {
                        headline: "Link",
                        breakpoint: "all",
                        align: "start",
                    }, {
                        headline: "Sourced through",
                        breakpoint: "all",
                        align: "start",
                    }
                ],
                pagination: this.select ? null : this.pageSize,
                // workaround to hide the pagination footer
                ajaxUrl: "/api/companies/press_releases?option=" + this.option + (this.select ? "&limit=" + this.pageSize : ""),
                selectAll: this.select,
                select: this.select,
                prettySelect: true,
                selectPosition: "pre",
            }
        },
    },
    watch: {},
    methods: {
        downloadCompanies() {
            const filters = JSON.stringify({
                relevantForUserId: this.userId,
                companyIds: this.companyIds,
                companiesHash: this.companiesHash,
                symbols: this.symbols,
            });

            const url = "/api/companies/press_releases/export?option=" + this.option + "&" + this.buildQueryString({filters});
            window.open(url, '_blank').focus();
        }
    }

}
