

import SaveButton from "../save-button.vue";
import TableActions from "../table-actions.vue";

export default {
    name: "StreetAlertModal",
    components: {TableActions, SaveButton},
    props: {
        multiple: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selected: this.multiple ? [] : null,
            keyword: "",
            companyGroup: null,
            companySpecificOnly: true,
            currentKeywords: [],
            loading: false,
            tableConfig: {
                columns: [
                    {
                        headline: "Keyword",
                    }, {
                        headline: "Company Group",
                    }, {
                        headline: "Company Specific News Only",
                    }, {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: 'end'
                    }
                ],
            },
        }
    },
    computed: {
        rows() {
            return this.currentKeywords.map((k) => {
                return [
                    {
                        text: k.term
                    },
                    {
                        text: k.companyGroup?.title
                    },
                    {
                        text: k.companySpecificOnly ? "X" : ""
                    },
                    {
                        type: "actions",
                        id: k.id
                    }
                ];
            })
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
            this.loadKeywords();
        });
    },
    methods: {
        submitForm() {

            this.loading = true;
            this.$axios.post('/api/street_account_alerts', {
                term: this.keyword,
                companyGroup: this.companyGroup ? this.companyGroup['@id'] : null,
                companySpecificOnly: !!this.companySpecificOnly
            }).then(() => {
                this.loadKeywords();
                this.keyword = "";
                this.companyGroup = null;
                this.companySpecificOnly = true;
                this.$formulate.reset('add-alert-form');
                this.loading = false;
            })


        },
        removeKeywords(id) {
            this.$axios.delete('/api/street_account_alerts/' + id).then(() => {
                this.loadKeywords();
            })
        },
        loadKeywords() {
            this.loading = true;
            this.$axios.get('/api/street_account_alerts?user=' + this.userInfo.id).then((response) => {
                this.currentKeywords = response.data;
            }).finally(() => {
                this.loading = false;
            });
        }
    },
}
