
import moment from "moment";

export default {
    name: "RecentDealsCard",
    data() {
        return {
            transactions: [],
            config: {
                columns: [
                    {
                        headline: 'Company',
                        sort: true
                    },
                    {
                        headline: "Announced On",
                        sort: true
                    }, {
                        headline: "Pricing Date",
                        sort: true
                    }, {
                        headline: "Investment Type",
                        sort: true,
                        breakpoint: 'all'
                    }, {
                        headline: "Transaction Type",
                        sort: true,
                        breakpoint: 'all'
                    }, {
                        headline: "Money Raised",
                        sort: true
                    }, {
                        headline: "Data Source",
                        sort: true,
                        breakpoint: 'all'
                    },
                ]
            }
        }
    },
    computed: {
        rows() {
            return this.transactions.map((transaction) => {
                return [
                    {
                        type: "company",
                        id: transaction.company.id,
                        title: transaction.company.name,
                    },
                    {
                        text: transaction.announcementDate ? this.formatDate(transaction.announcementDate) : '',
                        sortValue: transaction.announcementDate ? moment(transaction.announcementDate).unix() : Number.MAX_SAFE_INTEGER,
                    },
                    {
                        text: transaction.pricingDate ? this.formatDate(transaction.pricingDate) : '',
                        sortValue: transaction.pricingDate ? moment(transaction.pricingDate).unix() : Number.MAX_SAFE_INTEGER,
                    },
                    {
                        text: transaction.investmentType
                    },
                    {
                        text: transaction.transactionType
                    },
                    {
                        text: '$ ' + this.nFormatter(transaction.moneyRaisedUsd),
                        sortValue: transaction.moneyRaisedUsd,
                    },
                    {
                        text: transaction.dataSource
                    },
                ]
            })
        }
    },
    mounted() {
        this.loadDeals();
    },
    methods: {
        loadDeals() {
            this.$axios.get('/api/transactions/recent')
                .then((response) => {
                    this.transactions = response.data;
                })
        }
    }
}
