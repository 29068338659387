
import moment from "moment";
import PercentageChange from "../helpers/percentage-change";
import UserCoverageModal from "../users/user-coverage-modal";
import modals from "../../mixins/modals";

export default {
    name: "PeersList",
    components: {
        PercentageChange,
        UserCoverageModal,
    },
    mixins: [modals],
    props: {
        company: {
            type: Object,
            required: true,
        },
        peerCompanies: {
            type: Array,
            required: true,
        },
        peerGroupData: {
            type: Array,
            required: true
        },
        loadingManualPeers: {
            type: Boolean,
            required: true,
        },
        allowEdit: {
            type: Boolean,
            default: false,
        },
        perspective: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            coverageUserId: null,
            loadingPeersSuggestion: false,
            collapseShow: false,
            collapseHidden: true,
            manualPeerGroupsConfig: {
                columns: [
                    {
                        headline: "Symbol",
                        sort: true,
                    }, {
                        headline: "Name",
                        sort: true,
                    }, {
                        headline: "HQ",
                        breakpoint: "xl",
                    }, {
                        headline: "Mkt Cap",
                        sort: true,
                        breakpoint: "xl",
                    }, {
                        headline: "Cash",
                        title: "Cash and short term investments",
                        sort: true,
                        breakpoint: "xl",
                    }, {
                        headline: "Debt",
                        title: "Total Debt",
                        sort: true,
                        breakpoint: "xl",
                    }, {
                        headline: "Price",
                        sort: true,
                    }, {
                        headline: "% Chg",
                        sort: true,
                        breakpoint: "xl",
                    }, {
                        headline: "HCW Analysts",
                    },
                ],
                pagination: 10
            },
            updated: moment().unix(),
            manualPeerGroupForSearch: [],
            suggestionsPeerGroupsConfig: {
                columns: [
                    {
                        headline: "Symbol",
                        sort: true,
                    }, {
                        headline: "Name",
                        sort: true,
                    }, {
                        headline: "HQ",
                    }, {
                        headline: "Mkt Cap",
                        sort: true,
                    }, {
                        headline: "Cash",
                        title: "Cash and short term investments",
                        sort: true,
                    }, {
                        headline: "Debt",
                        title: "Total Debt",
                        sort: true,
                    }, {
                        headline: "Price",
                        sort: true,
                    }, {
                        headline: "% Chg",
                        sort: true,
                    }, {
                        headline: "HCW Analysts",
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end"
                    },
                ],
                pagination: 10
            },
            suggestionsPeerGroupCompanies: [],
            suggestionsPeerGroupData: [],
        }
    },
    computed: {
        rows() {
            return this.peerCompanies.map(item => {

                let address = "";
                if (item.address) {
                    address = item.address.replace("\n", ", ");
                }

                let stockInfo = this.peerGroupData.find(obj => {
                    return obj.symbol === item.ticker
                });
                if (!stockInfo) {
                    stockInfo = null;
                }
                if (stockInfo && !address) {
                    address = [stockInfo.city, stockInfo.state, stockInfo.country].filter(el => el && el.length > 0).join(", ")
                }
                let cash = null;
                let debt = null;
                let balanceSheetCurrency = "";
                let balanceSheetDate = "";
                if (stockInfo && stockInfo.mostRecentBalanceSheet) {
                    balanceSheetDate = this.formatDate(stockInfo.mostRecentBalanceSheet.date);
                    if (stockInfo.mostRecentBalanceSheet.reportedCurrency) {
                        balanceSheetCurrency = stockInfo.mostRecentBalanceSheet.reportedCurrency
                    }
                    if (stockInfo.mostRecentBalanceSheet.cashAndShortTermInvestments) {
                        cash = stockInfo.mostRecentBalanceSheet.cashAndShortTermInvestments
                    }
                    if (stockInfo.mostRecentBalanceSheet.totalDebt) {
                        debt = stockInfo.mostRecentBalanceSheet.totalDebt
                    }
                }

                return {
                    classes: item.id === this.company.id ? "selected-company" : "",
                    cells: [
                        {
                            text: item.ticker
                        },
                        {
                            type: 'name',
                            item,
                            sortValue: item.name
                        },
                        {
                            text: address,
                        },
                        {
                            text: stockInfo ? this.nFormatCurrency(stockInfo.currency, stockInfo.marketCap) : "",
                            sortValue: stockInfo ? stockInfo.marketCap : 0,
                        },
                        {
                            html: cash ? this.nFormatCurrency(balanceSheetCurrency, cash) + "<br/><span class='text-muted'>(" + balanceSheetDate + ")</span>" : "",
                            sortValue: cash,
                        },
                        {
                            html: debt ? this.nFormatCurrency(balanceSheetCurrency, debt) + "<br/><span class='text-muted'>(" + balanceSheetDate + ")</span>" : "",
                            sortValue: debt,
                        },
                        {
                            text: stockInfo ? this.formatCurrency(stockInfo.price, stockInfo.currency) : "",
                            sortValue: stockInfo ? stockInfo.price : 0,
                        },
                        {
                            type: 'percentage-change',
                            change: stockInfo ? stockInfo.changesPercentage : '',
                            sortValue: stockInfo ? stockInfo.changesPercentage : 0,
                        },
                        {
                            type: 'ratings',
                            ratings: stockInfo ? stockInfo.ratings : [],
                        },
                    ]
                }
            });
        },
        suggestionsPeerRows() {
            return this.suggestionsPeerGroupCompanies.map(item => {

                let address = "";
                if (item.address) {
                    address = item.address.replace("\n", ", ");
                }

                let stockInfo = this.suggestionsPeerGroupData.find(obj => {
                    return obj.symbol === item.ticker
                });
                if (!stockInfo) {
                    stockInfo = null;
                }
                if (stockInfo && !address) {
                    address = [stockInfo.city, stockInfo.state, stockInfo.country].filter(el => el && el.length > 0).join(", ")
                }
                let cash = null;
                let debt = null;
                let balanceSheetCurrency = "";
                let balanceSheetDate = "";
                if (stockInfo && stockInfo.mostRecentBalanceSheet) {
                    balanceSheetDate = this.formatDate(stockInfo.mostRecentBalanceSheet.date);
                    if (stockInfo.mostRecentBalanceSheet.reportedCurrency) {
                        balanceSheetCurrency = stockInfo.mostRecentBalanceSheet.reportedCurrency
                    }
                    if (stockInfo.mostRecentBalanceSheet.cashAndShortTermInvestments) {
                        cash = stockInfo.mostRecentBalanceSheet.cashAndShortTermInvestments
                    }
                    if (stockInfo.mostRecentBalanceSheet.totalDebt) {
                        debt = stockInfo.mostRecentBalanceSheet.totalDebt
                    }
                }

                return {
                    classes: item.id === this.company.id ? "selected-company" : "",
                    cells: [
                        {
                            text: item.ticker
                        },
                        {
                            type: 'name',
                            item,
                            sortValue: item.name
                        },
                        {
                            text: address,
                        },
                        {
                            text: stockInfo ? this.nFormatCurrency(stockInfo.currency, stockInfo.marketCap) : "",
                            sortValue: stockInfo ? stockInfo.marketCap : 0,
                        },
                        {
                            html: cash ? this.nFormatCurrency(balanceSheetCurrency, cash) + "<br/><span class='text-muted'>(" + balanceSheetDate + ")</span>" : "",
                            sortValue: cash,
                        },
                        {
                            html: debt ? this.nFormatCurrency(balanceSheetCurrency, debt) + "<br/><span class='text-muted'>(" + balanceSheetDate + ")</span>" : "",
                            sortValue: debt,
                        },
                        {
                            text: stockInfo ? this.formatCurrency(stockInfo.price, stockInfo.currency) : "",
                            sortValue: stockInfo ? stockInfo.price : 0,
                        },
                        {
                            type: 'percentage-change',
                            change: stockInfo ? stockInfo.changesPercentage : '',
                            sortValue: stockInfo ? stockInfo.changesPercentage : 0,
                        },
                        {
                            type: 'ratings',
                            ratings: stockInfo ? stockInfo.ratings : [],
                        },
                        {
                            item,
                            type: "add-to-manual",
                            hash: this.createSimpleHash(item.name),
                        },
                    ]
                }
            });
        },
    },
    watch: {
        peerCompanies: {
            handler() {
                this.manualPeerGroupForSearch = this.peerCompanies.filter(x => x.id !== this.company.id);
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        if (this.allowEdit) {
            this.loadSuggestionsPeerGroups();
            this.$refs.suggestionCollapse.addEventListener('show.bs.collapse', () => {
                this.collapseShow = true;
                this.collapseHidden = false;
            })
            this.$refs.suggestionCollapse.addEventListener('hide.bs.collapse', () => {
                this.collapseShow = false;
            })
            this.$refs.suggestionCollapse.addEventListener('hidden.bs.collapse', () => {
                this.collapseHidden = true;
            })
        }
    },
    methods: {
        updatePeerGroup() {
            const data = {
                peerGroup: this.manualPeerGroupForSearch,
                perspective: this.perspective
            };
            this.$emit("update-peer-group", data);
        },
        addToManualPeerGroup(id) {
            this.manualPeerGroupForSearch.push({id});
            const data = {
                peerGroup: this.manualPeerGroupForSearch,
                perspective: this.perspective
            };
            this.$emit("update-peer-group", data);
            setTimeout(() => {
                this.loadSuggestionsPeerGroups();
            }, 1000);
        },
        loadSuggestionsPeerGroups() {
            this.loadingPeersSuggestion = true;
            this.$axios.get("/api/companies/peer_group_suggestion/" + this.company.id)
                .then((response) => {
                    this.suggestionsPeerGroupCompanies = response.data.companies;
                    this.suggestionsPeerGroupData = response.data.stockData;
                    this.updated = moment().unix()
                }).finally(() => {
                    this.loadingPeersSuggestion = false;
                });
        },
        openCoverageModal(userId) {
            this.coverageUserId = userId;
            this.$nextTick(() => {
                this.coverageModal = this.openModal(this.$refs.userCoverageModal);
            });
        },
    }
}
