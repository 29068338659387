

import SaveButton from "../../save-button.vue";
import iriPreparation from "../../../mixins/iri-preparation";

export default {
    name: "UpdateColumnsModal",
    components: {SaveButton},
    mixins: [iriPreparation],
    props: {
        initialHiddenColumns: {
            type: Array,
            required: true
        },
        targetingGroup: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            statusByColumn: {
                aum: true,
                hcwAdditionalNames: true,
                onCompanyMetWithList: true,
                onCompanyTargetList: true,
                meetingDate: true,
                meetingType: true,
                lastPeerMeeting: true,
                currentShareholder: true,
                peerShareholder: true,
                manualShareholder: true,
                doNotContact: true,
                hcwStatus: true,
                hcwComments: true,
                companyComments: true,
                cdaSigned: true,
                dataRoomAccessGiven: true,
                dataRoomAccessed: true,
                feedback: true,
                other: true,
            },
            labels: {
                aum: "Assets under Management (Export only)",
                hcwAdditionalNames: "HCW Additional Names",
                onCompanyMetWithList: "On Company Met With List",
                onCompanyTargetList: "On Company Target List",
                meetingDate: "Meeting Date",
                meetingType: "Meeting Type",
                lastPeerMeeting: "Last Peer Meeting",
                currentShareholder: "Current Shareholder",
                peerShareholder: "Peer Shareholder",
                manualShareholder: "Manual Shareholder",
                doNotContact: "Do Not Contact",
                hcwStatus: "HCW Status",
                hcwComments: "HCW Comments",
                companyComments: "Company Comments",
                cdaSigned: "CDA Signed",
                dataRoomAccessGiven: "Data Room Access Given",
                dataRoomAccessed: "Data Room Accessed",
                feedback: "Feedback",
                other: "Other",
            },
            loading: false
        }
    },
    watch: {
        initialHiddenColumns: {
            handler() {
                if(this.initialHiddenColumns && this.initialHiddenColumns.length > 0) {
                    this.initialHiddenColumns.forEach(column => {
                        this.statusByColumn[column] = false;
                    });
                }
            },
            immediate: true
        }
    },
    methods: {
        submitForm() {
            this.loading = true;

            const hiddenColumns = [];
            for (const [key, value] of Object.entries(this.statusByColumn)) {
                if (!value) {
                    hiddenColumns.push(key);
                }
            }

            this.$axios.patch(`/api/targeting_groups/${this.targetingGroup.id}`, {
                hiddenColumns
            }, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            }).then(response => {
                this.loading = false;
                this.$emit("columns-updated", response.data);
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
