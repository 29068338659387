

import SaveButton from "../../save-button.vue";
import iriPreparation from "../../../mixins/iri-preparation";

export default {
    name: "AddMemberModal",
    components: {SaveButton},
    mixins: [iriPreparation],
    props: {
        targetingGroup: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            contactGroupsToAdd: [],
            contactsToAdd: [],
            companyGroupsToAdd: [],
            companiesToAdd: [],
            companyComment: "",
        }
    },
    methods: {
        submitForm() {
            this.loading = true;

            this.$axios.patch(`/api/targeting_groups/${this.targetingGroup.id}/add_members`, {
                contactGroupIds: this.contactGroupsToAdd.map(group => group.id),
                contactIds: this.contactsToAdd.map(contact => contact.id),
                companyGroupIds: this.companyGroupsToAdd.map(group => group.id),
                companyIds: this.companiesToAdd.map(company => company.id),
                companyComment: this.companyComment || null,
            }, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            }).then(response => {
                this.$emit("members-added");
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Members Added",
                    message: "The selected members have been added successfully.",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
