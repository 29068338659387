
import SaveButton from "../save-button.vue";
import LoadingSpinner from "../loading-spinner.vue";
import ModalHeader from '~/components/modal-header.vue';

export default {
    name: "SyncCompanyToSessionsModal",
    components: {
        LoadingSpinner,
        SaveButton,
        ModalHeader
    },
    props: {
        event: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            selectedCompanyGroup: null,
            companiesLoaded: false,
            companiesToEvent: [],
            companiesToSessions: [],
            syncErrors: [],
            tracks: null,
            selectedTrack: null
        }
    },
    watch: {
        selectedCompanyGroup(cg) {
            this.$axios.get(`/api/events/${this.event.id}/sessions_companies_sync_check/${cg.id}`).then((res) => {
                this.companiesToEvent = res.data.event;
                this.companiesToSessions = res.data.sessions;
                this.companiesLoaded = true;
            })
        }
    },
    mounted() {
        this.$axios.get(`/api/events/${this.event.id}/sessions_tracks`).then((res) => {
            this.tracks = res.data.map((track) => {
                return {
                    id: track.id,
                    label: `${track.code} - ${track.type}`
                }
            });
        });
    },
    methods: {
        handleSubmit() {
            this.loading = true;

            this.$axios.post(`/api/events/${this.event.id}/sessions_companies_sync_trigger/${this.selectedCompanyGroup.id}`, {
                trackId: this.selectedTrack.id
            }).then((res) => {
                const toastId = this.generateUUID();
                this.syncErrors = res.data.errors;

                if (!res.data.success) {
                    this.addToast({
                        type: "danger",
                        title: `Adding ${res.data.synced > 0 ? 'partially' : ''} failed`,
                        message: "When syncing companies to Sessions, some companies could not be added.",
                        id: toastId,
                    });
                } else {
                    this.addToast({
                        type: "success",
                        title: "Adding successful",
                        message: "The companies from the list have been pushed to Sessions and added to the event.",
                        id: toastId,
                    });
                    this.$emit('company-updated');
                }

                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
            }).finally(() => {
                this.loading = false;
            });
        },
    }
}
