

import SaveButton from "../../save-button.vue";
import iriPreparation from "../../../mixins/iri-preparation";

export default {
    name: "AddCompanyContactModal",
    components: {SaveButton},
    mixins: [iriPreparation],
    props: {
        targetingGroupMember: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            contact: null,
            initialContacts: [],
        }
    },
    watch: {
        targetingGroupMember: {
            handler() {
                this.loadInitialContacts();
                this.resetForm();
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        submitForm() {
            this.loading = true;
            this.$axios.patch(`/api/targeting_group_members/${this.targetingGroupMember.id}/add_contact`, {
                contactId: this.contact.id
            }, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            }).then(response => {
                this.$emit('contact-added-to-company', response.data);
            }).catch(() => {
                this.resetForm();
            });
        },
        loadInitialContacts() {
            this.$axios.get(`/api/contacts/for_select?company=${this.targetingGroupMember.company.id}`).then(response => {
                this.initialContacts = response.data['hydra:member'];
            });
        },
        resetForm() {
            try {
                this.$formulate.reset('bulk-operation-form');
            } catch (TypeError) {
                console.log("form reset failed");
            }
            this.loading = false;
        }
    }
}
