

import moment from "moment";
import LineChart from "../line-chart";
import SkeletonChart from "../skeleton/skeleton-chart";

export default {
    name: "StockChartCard",
    components: {SkeletonChart, LineChart},
    props: {
        company: {
            type: Object,
            required: true
        },
        uuid: {
            type: String,
            default: "stock-chart",
        }
    },
    data() {
        return {
            chartDataLoaded: false,
            stockInfoLoaded: false,
            timespan: '1D',
            timespans: ['1D', '5D', '1M', '3M', '6M', 'YTD', '1Y', 'Max'],
            availableTimespans: ['1D', '5D', '1M', '3M', '6M', 'YTD', '1Y', 'Max'],
            data: {
                labels: [],
                datasets: [{
                    label: '',
                    lineTension: 0.01,
                    backgroundColor: 'rgba(52, 168, 83,0.4)',
                    borderColor: 'rgba(52, 168, 83,0.9)',
                    borderWidth: 2,
                    data: [],
                    yAxisID: "priceAxis",
                    pointHoverRadius: 0,
                }, {
                    type: 'bar',
                    label: 'Volume',
                    data: [],
                    yAxisID: "volumeAxis"
                }],
            },
            options: {
                legend: {
                    display: false,
                },
                tooltips: {
                    enabled: true,
                    mode: 'single',
                    displayColors: false,
                    callbacks: {
                        label: (tooltipItem) => {
                            if (tooltipItem.datasetIndex === 0) {
                                return this.formatCurrency(tooltipItem.yLabel, this.currency);
                            } else {
                                return "Volume: " + Number(tooltipItem.yLabel).toLocaleString();
                            }
                        }
                    }
                },
                elements: {
                    point: {
                        radius: 0.1,
                        hitRadius: 5,
                    },
                },
                scales: {
                    yAxes: [
                        {
                            id: "priceAxis",
                            position: 'right',
                            type: 'linear',
                            ticks: {
                                beginAtZero: false,
                            },
                        },
                        {
                            id: "volumeAxis",
                            position: 'left',
                            type: 'linear',
                            display: false,
                        }
                    ],
                    xAxes: [
                        {
                            type: 'time',
                            distribution: 'series',
                            time: {
                                unit: 'day',
                                displayFormats: {
                                    day: 'DD MMM',
                                },
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 4,
                                maxRotation: 0,
                                minRotation: 0,
                            },
                        },
                    ],
                },
            },
            prevClose: null,
            currPrice: null,
            currency: null,
        }
    },
    computed: {
        daysByTimespan() {
            let days = 1;
            switch (this.timespan) {
                case "1D":
                    days = 1;
                    break;
                case "5D":
                    days = 5;
                    break;
                case "1M":
                    // load data with buffer
                    days = 31;
                    break;
                case "3M":
                    // load data with buffer
                    days = 92;
                    break;
                case "6M":
                    // load data with buffer
                    days = 184;
                    break;
                case "YTD":
                    days = moment().diff(moment([moment().year(), 0, 1]), 'days') + 1;
                    break;
                case "1Y":
                    // load data with buffer
                    days = 366;
                    break;
                case "Max":
                    days = "Max";
                    break;
                default:
                    console.error("Wrong config: " + this.timespan);
                    break;
            }
            return days;
        },
        finishedLoading() {
            return this.chartDataLoaded && this.stockInfoLoaded;
        },
        chartDate() {
            if (this.data && this.data.labels) {
                if (this.data.labels[0].length > 10) {
                    // Show time as we have it
                    return moment(this.data.labels[0]).format('lll');
                } else {
                    // Hide time as we do not have it
                    return moment(this.data.labels[0]).format('ll');
                }
            } else {
                return null;
            }
        },
        readableTimespan() {
            switch (this.timespan) {
                case '1D':
                    return 'Today';
                case 'YTD':
                case 'Max':
                    return this.timespan;
                default:
                    return [this.timespan.slice(0, 1), this.timespan.slice(1)].join(' ') + '.';
            }
        },
        absChange() {
            // if(this.data && this.data.datasets && this.data.datasets.length > 0 && this.data.datasets[0].data && this.data.datasets[0].data[0].length > 0) {
            switch (this.timespan) {
                case "1D":
                    return this.data.datasets[0].data[0] - this.prevClose;
                case "5D":
                case "1M":
                case "3M":
                case "6M":
                case "YTD":
                case "1Y":
                case "Max":
                    return this.data.datasets[0].data[0] - this.data.datasets[0].data[this.data.datasets[0].data.length - 1];
                default:
                    console.error("Invalid timespan: " + this.timespan);
                    return 0.00;
            }
        },
        relChange() {
            // if(this.data && this.data.datasets && this.data.datasets.length > 0 && this.data.datasets[0].data && this.data.datasets[0].data[0].length > 0) {
            switch (this.timespan) {
                case "1D":
                    return ((this.data.datasets[0].data[0] / this.prevClose) - 1) * 100;
                case "5D":
                case "1M":
                case "3M":
                case "6M":
                case "YTD":
                case "1Y":
                case "Max":
                    return ((this.data.datasets[0].data[0] / this.data.datasets[0].data[this.data.datasets[0].data.length - 1]) - 1) * 100;
                default:
                    console.error("Invalid timespan: " + this.timespan);
                    return 0.00;
            }
        }
    },
    watch: {
        relChange: {
            handler() {
                // set chart color
                if (this.relChange >= 0) {
                    // green
                    this.data.datasets[0].backgroundColor = 'rgba(52, 168, 83,0.4)';
                    this.data.datasets[0].borderColor = 'rgba(52, 168, 83,0.9)';
                } else {
                    // red
                    this.data.datasets[0].backgroundColor = 'rgba(235, 15, 41, 0.4)';
                    this.data.datasets[0].borderColor = 'rgba(235, 15, 41, 0.9)';
                }
            },
            immediate: true,
        }
    },
    mounted() {
        if (this.company.ticker) {
            this.updateData(this.daysByTimespan);
            this.loadQuote();
        }
    },
    beforeDestroy() {
    },
    methods: {
        /**
         * Update data and active btn class and selected company symbol
         */
        async updateData(days) {
            const finalDays = await this.tryToUpdateChartData(days);
            if (finalDays === "Max" || finalDays > 0) {
                this.setOptions(finalDays);
                this.$nextTick(() => {
                    if (this.$refs.stockChart) {
                        this.$refs.stockChart.updateChart();
                    }
                });
            }
            this.chartDataLoaded = true;
        },
        /**
         * Update data.
         *
         * Returns for which number of days the chart could be updated with.
         */
        async tryToUpdateChartData(days) {
            let finalDays = null;

            while (finalDays == null) {
                const apiURL = this.getURL(days);

                await this.$axios.$get(apiURL).then((res) => {
                    if (this.tryToSetChartData(days, res)) {
                        // Data was found which can be displayed.
                        finalDays = days;

                    } else {
                        // No data (yet) to display, keep looking.
                        const index = this.availableTimespans.indexOf(this.timespan);
                        this.availableTimespans.splice(index, 1);

                        if (index < this.availableTimespans.length) {
                            // There is another timespan to check.
                            this.timespan = this.availableTimespans[index];
                            days = this.daysByTimespan;

                        } else {
                            // No more timespans to check.
                            finalDays = -1;
                        }
                    }
                });
            }

            return finalDays;
        },
        /**
         * Set datasets label
         */
        setLabel(label) {
            this.data.datasets[0].label = label
        },
        /**
         * Get the API URL
         */
        getURL(days) {
            switch (days) {
                case 1:
                    return `/api/companies/stock_info/${this.company.id}/historical-chart?slots=1min&timeseries=${days}`;
                case 5:
                    return `/api/companies/stock_info/${this.company.id}/historical-chart?slots=15min&timeseries=${days}`;
                case 'Max':
                    return `/api/companies/stock_info/${this.company.id}/historical-price-full`;
                default:
                    return `/api/companies/stock_info/${this.company.id}/historical-price-full?timeseries=${days}`;
            }
        },
        /**
         * Set chart data.
         *
         * Return whether chart data for the selected number of days is available.
         */
        tryToSetChartData(days, res) {
            const newData = [];
            const newVolumeData = [];
            const newLabels = [];
            switch (days) {
                case 1:
                    res.forEach((result) => {
                        // Only 1 day
                        const firstDateSTR = res[0].date
                        const firstDate = firstDateSTR.split(' ')[0]
                        const currentDateSTR = result.date
                        const currentDate = currentDateSTR.split(' ')[0]
                        // Only 1 day
                        if (currentDate !== firstDate) return
                        newData.push(result.open)
                        newVolumeData.push(result.volume);
                        newLabels.push(result.date)
                    });
                    break
                case 5:
                    res.forEach((result) => {
                        // Only 5 days
                        const firstDateSTR = res[0].date
                        const firstDate = new Date(firstDateSTR)
                        const currentDateSTR = result.date
                        const currentDate = new Date(currentDateSTR)
                        const dateDiff = Math.abs(firstDate - currentDate)
                        // Only 5 days
                        if (dateDiff > 596200005) return
                        newData.push(result.open)
                        newVolumeData.push(result.volume);
                        newLabels.push(result.date)
                    });
                    break
                default:
                    // eslint-disable-next-line no-case-declarations
                    let lastDate = null;
                    switch (this.timespan) {
                        case "1M":
                            lastDate = moment().subtract(1, "months");
                            break;
                        case "3M":
                            lastDate = moment().subtract(3, "months");
                            break;
                        case "6M":
                            lastDate = moment().subtract(6, "months");
                            break;
                        case "YTD":
                            lastDate = moment([moment().year(), 0, 1]);
                            break;
                        case "1Y":
                            lastDate = moment().subtract(1, "years");
                            break;
                        case "Max":
                            if (res.historical) {
                                lastDate = moment(res.historical[res.historical.length - 1].date, ["YYYY-MM-DD", "YYYY-MM-DD HH:mm:ss"]);
                            }
                            break;
                        default:
                            console.error("Invalid timespan: " + this.timespan);
                            return;
                    }

                    if (lastDate !== null && res.historical) {
                        for (let i = 0; i < res.historical.length; i++) {
                            if (moment(res.historical[i].date, ["YYYY-MM-DD", "YYYY-MM-DD HH:mm:ss"]) < lastDate) {
                                break;
                            }

                            newData.push(res.historical[i].close);
                            newVolumeData.push(res.historical[i].volume);
                            newLabels.push(res.historical[i].date);
                        }
                    }
            }

            if (newData.length === 0) {
                return false;
            }

            this.data.datasets[0].data = newData
            this.data.datasets[1].data = newVolumeData
            this.data.labels = newLabels

            // set chart color
            if (newData.at(-1) >= newData.at(0)) { // array is reversed
                // red
                this.data.datasets[0].backgroundColor = 'rgba(235, 15, 41, 0.4)';
                this.data.datasets[0].borderColor = 'rgba(235, 15, 41, 0.9)';
            } else {
                // green
                this.data.datasets[0].backgroundColor = 'rgba(52, 168, 83,0.4)';
                this.data.datasets[0].borderColor = 'rgba(52, 168, 83,0.9)';
            }

            return true;
        },
        /**
         * Set chart options
         */
        setOptions(days) {
            switch (days) {
                case 1:
                    this.options = {
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            display: false,
                        },
                        tooltips: {
                            enabled: true,
                            mode: 'single',
                            displayColors: false,
                            callbacks: {
                                label: (tooltipItem) => {
                                    if (tooltipItem.datasetIndex === 0) {
                                        return this.formatCurrency(tooltipItem.yLabel, this.currency);
                                    } else {
                                        return "Volume: " + Number(tooltipItem.yLabel).toLocaleString();
                                    }
                                }
                            }
                        },
                        elements: {
                            point: {
                                radius: 0.1,
                                hitRadius: 5,
                            },
                        },
                        scales: {
                            yAxes: [{
                                        id: "priceAxis",
                                        position: 'right',
                                        type: 'linear',
                                        ticks: {
                                            beginAtZero: false,
                                        },
                                    },
                                    {
                                        id: "volumeAxis",
                                        position: 'left',
                                        type: 'linear',
                                        display: false,
                                    }],
                            xAxes: [
                                {
                                    distribution: "series",
                                    type: "time",
                                    time: {
                                        unit: 'minute',
                                        displayFormats: {
                                            minute: 'MM/DD/YYYY hh:mm a',
                                        },
                                        tooltipFormat: 'hh:mm a',
                                    },
                                    ticks: {
                                        callback(val, index) {
                                            if (val.endsWith('00 am') || val.endsWith('00 pm')) {
                                                return moment(val).format('hh:mm a');
                                            } else {
                                                return null;
                                            }
                                        },
                                        autoSkip: false,
                                        maxTicksLimit: Number.MAX_SAFE_INTEGER,
                                        maxRotation: 0,
                                        minRotation: 0,
                                    },
                                },
                            ],
                        },
                    }

                    break
                case 5:
                    this.options = {
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            display: false,
                        },
                        tooltips: {
                            enabled: true,
                            mode: 'single',
                            displayColors: false,
                            callbacks: {
                                label: (tooltipItem) => {
                                    if (tooltipItem.datasetIndex === 0) {
                                        return this.formatCurrency(tooltipItem.yLabel, this.currency);
                                    } else {
                                        return "Volume: " + Number(tooltipItem.yLabel).toLocaleString();
                                    }
                                }
                            }
                        },
                        elements: {
                            point: {
                                radius: 0.1,
                                hitRadius: 5,
                            },
                        },
                        scales: {
                            yAxes: [{
                                        id: "priceAxis",
                                        position: 'right',
                                        type: 'linear',
                                        ticks: {
                                            beginAtZero: false,
                                        },
                                    },
                                    {
                                        id: "volumeAxis",
                                        position: 'left',
                                        type: 'linear',
                                        display: false,
                                    }],
                            xAxes: [
                                {
                                    distribution: "series",
                                    type: "time",
                                    time: {
                                        unit: 'minute',
                                        displayFormats: {
                                            minute: 'MM/DD/YYYY hh:mm a',
                                        },
                                        tooltipFormat: 'MM/DD/YYYY hh:mm a',
                                    },
                                    ticks: {
                                        callback(val, index) {
                                            if (val.endsWith('09:30 am')) {
                                                return moment(val).format('MM/DD/YYYY');
                                            } else {
                                                return null;
                                            }
                                        },
                                        autoSkip: false,
                                        maxTicksLimit: Number.MAX_SAFE_INTEGER,
                                        maxRotation: 0,
                                        minRotation: 0,
                                    },
                                },
                            ],
                        },
                    }

                    break;
                default:
                    this.options = {
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            display: false,
                        },
                        tooltips: {
                            enabled: true,
                            mode: 'single',
                            displayColors: false,
                            callbacks: {
                                label: (tooltipItem) => {
                                    if (tooltipItem.datasetIndex === 0) {
                                        return this.formatCurrency(tooltipItem.yLabel, this.currency);
                                    } else {
                                        return "Volume: " + Number(tooltipItem.yLabel).toLocaleString();
                                    }
                                }
                            }
                        },
                        elements: {
                            point: {
                                radius: 0.1,
                                hitRadius: 5,
                            },
                        },
                        scales: {
                            yAxes: [{
                                        id: "priceAxis",
                                        position: 'right',
                                        type: 'linear',
                                        ticks: {
                                            beginAtZero: false,
                                        },
                                    },
                                    {
                                        id: "volumeAxis",
                                        position: 'left',
                                        type: 'linear',
                                        display: false,
                                    }],
                            xAxes: [
                                {
                                    type: 'time',
                                    distribution: 'series',
                                    time: {
                                        unit: 'day',
                                        displayFormats: {
                                            day: 'MM/DD/YY',
                                        },
                                        tooltipFormat: 'MM/DD/YY',
                                    },
                                    ticks: {
                                        autoSkip: true,
                                        maxTicksLimit: 5,
                                        maxRotation: 0,
                                        minRotation: 0,
                                    },
                                },
                            ],
                        },
                    }
            }
        },
        searchWithYahoo() {
            const query = this.company.ticker;
            window.open("https://finance.yahoo.com/quote/" + query, '_blank').focus();
        },
        loadQuote() {
            this.$axios.get(`/api/companies/stock_info/${this.company.id}/quote`)
                .then((response) => {
                    if (response.data.length > 0) {
                        const companyData = response.data[0];
                        this.prevClose = companyData.previousClose;
                        this.currPrice = companyData.price;
                        this.currency = companyData.currency;
                    }
                    this.stockInfoLoaded = true;
                })
        },
        updateTimespan(timespan) {
            this.timespan = timespan;
            this.updateData(this.daysByTimespan);
        }
    }
}
