

import {mapActions} from "vuex";
import iriPreparation from "../../../mixins/iri-preparation";
import SaveButton from "../../save-button.vue";

export default {
    name: "ImportModal",
    components: {SaveButton},
    mixins: [iriPreparation],
    props: {
        company: {
            type: Object,
            required: true
        },
        importId: {
            type: Number,
            required: true
        }

    },
    data() {
        return {
            headlinesBySheet: null,
            sheetsToImport: {},
            typeByHeadlineAndSheet: {},
            columnOptions: [],
            quarter: null,
            quarterOptions: [],
            year: null,
            yearOptions: [],
            previewData: null,
            toggleViewUpdate: false,
            previewsBySheet: null,
            submitting: false,
            preview: true,
        }
    },
    computed: {},
    watch: {},
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', () => {
            this.loadHeadlines();
        });
    },
    methods: {
        ...mapActions({
            setPreventErrorHandling: "app/setPreventErrorHandling",
            setCurrentError: 'app/setCurrentError',
        }),
        submitForm() {
            this.setPreventErrorHandling(true);
            this.submitting = true;
            this.previewsBySheet = null;
            this.$axios.post(`/api/third_party_meeting_imports/${this.importId}/proceed`, {
                company_id: this.company.id,
                sheets: this.sheetsToImport,
                typeByHeadlineAndSheet: this.typeByHeadlineAndSheet,
                quarter: this.quarter,
                year: this.year,
                preview: this.preview
            })
                .then(response => {
                    if (this.preview) {
                        this.previewsBySheet = response.data.previewsBySheet;
                    } else {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Excel import finished",
                            message: "The third party meeting import has been performed successfully.",
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                            this.$emit('import-done', response.data.id);
                        })
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status && error.response.status === 400) {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "danger",
                            title: "Error",
                            message: error.response.data.error,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        })
                    } else {
                        this.setCurrentError(error);
                    }
                    console.log(error);
                })
                .finally(() => {
                    this.submitting = false;
                    this.setPreventErrorHandling(false);
                });
        },
        loadHeadlines() {
            this.$axios.get(`/api/third_party_meeting_imports/${this.importId}/headlines`)
                .then(response => {
                    this.sheets = response.data.sheets;
                    for (const sheet of this.sheets) {
                        this.sheetsToImport[sheet] = false;
                        this.typeByHeadlineAndSheet[sheet] = {};
                    }

                    this.headlinesBySheet = response.data.headlines;
                    this.columnOptions = response.data.columnOptions;
                    this.quarterOptions = response.data.quarterOptions;
                    this.yearOptions = response.data.yearOptions;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        oninput(e, sheet, headline) {
            this.$set(this.typeByHeadlineAndSheet[sheet], headline, e);
            this.toggleViewUpdate = !this.toggleViewUpdate;
        },
    }
}
