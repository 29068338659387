
import LoadingSpinner from "../loading-spinner.vue";
import SaveButton from "../save-button.vue";
import {ATTENDANCE_OPTIONS} from "../../mixins/attending";

export default {
    name: "RemoveFromEventModal",
    components: {SaveButton, LoadingSpinner},
    props: {
        companyId: {
            type: Number,
            required: true,
        },
        eventId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            ATTENDANCE_OPTIONS,
            uuid: this.generateUUID(),
            isLoading: false,
            eventHasCompany: null,
            contactEventParticipations: [],
            interactions: [],
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
            this.isLoading = true;

            this.$axios.get(`/api/events/company_at_event/${this.eventId}/${this.companyId}`).then(response => {
                this.eventHasCompany = response.data.eventHasCompany;
                this.contactEventParticipations = response.data.contactEventParticipations;
                this.interactions = response.data.interactions;

                this.isLoading = false;
            })
        });

        this.$refs.modal.addEventListener('hide.bs.modal', () => {
            this.eventHasCompany = null;
            this.contactEventParticipations = [];
            this.interactions = [];
        });
    },
    methods: {
        removeFromEvent() {
            this.isLoading = true;

            const promises = [];

            if (this.eventHasCompany) {
                promises.push(this.$axios.delete(`/api/event_has_companies/${this.eventHasCompany.id}`));
            }

            for (const contactEventParticipation of this.contactEventParticipations) {
                promises.push(this.$axios.delete(`/api/contact_event_participations/${contactEventParticipation.id}`));
            }

            Promise.all(promises).then(() => {
                this.$emit('removed');
                this.isLoading = false;
            });
        }
    }
}
