
import Swiper from 'swiper/swiper-bundle.min'
import 'swiper/swiper-bundle.min.css'

export default {
    name: 'PeerStockSlider',
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            manualPeerGroupCompaniesIds: [],
            peerData: [],
            companyIdByTicker: null,
            swiper: null,
        }
    },
    async mounted() {
        await this.loadPeerData();
        await this.initializeSwiper();
    },
    methods: {
        async loadPeerData() {
            await this.$axios.get(`/api/companies/peer_group_data/${this.company.id}?${this.buildQueryString({manual: true})}`)
                .then((response) => {
                    this.peerData = response.data.quotes;
                    this.companyIdByTicker = response.data.idsByTicker;
                    this.coveredByTicker = response.data.coveredByTicker;
                });
        },
        async initializeSwiper() {
            await this.$nextTick();
            // eslint-disable-next-line no-new
            this.swiper = new Swiper('.swiper-container', {
                loop: true,
                autoplay: {
                    delay: 4000,
                    disableOnInteraction: false
                },
                freeMode: {
                    enabled: true,
                    sticky: false,
                },
                spaceBetween: 15,
                slidesPerView: "auto",
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }

            });
        }
    }
}
