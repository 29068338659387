
import SaveButton from "../save-button.vue";

export default {
    name: "RequestStaffingModal",
    components: {SaveButton},
    props: {
        companyId: {
            type: [Object, Array, String, Number],
            default: null,
        },
        contactId: {
            type: Number,
            default: null,
        },
        type: {
            type: String,
            default: "danger",
            validator: function validator(value) {
                return ["danger", "info", "success", "primary"].includes(value);
            }
        },
        confirmText: {
            type: String,
            default: "Yes"
        },
        cancelText: {
            type: String,
            default: "Cancel"
        }
    },
    data() {
        return {
            confirmed: false,
            loading: false,
            userManagesCompanyRoleId: null,
            loaded: false,
            roles: [],
        }
    },
    watch: {
        companyId(newVal, oldVal) {
            console.log("companyId changed!", {newVal, oldVal});
            if (newVal !== oldVal) {
                this.loadRoles();
            }
        },
        contactId(newVal, oldVal) {
            console.log("contactId changed!", {newVal, oldVal});
            if (newVal !== oldVal) {
                this.loadRoles();
            }
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('hidden.bs.modal', () => {
            if (!this.confirmed) {
                this.$emit('canceled', this.companyId, this.userManagesCompanyRoleId)
            }
            this.confirmed = false;
            this.loading = false;
            this.roles = [];
            this.userManagesCompanyRoleId = null;
        });

        this.$refs.modal.addEventListener('show.bs.modal', () => {
            this.loadRoles();
        });
    },
    methods: {
        confirm() {
            this.loading = true;
            this.confirmed = true;
            this.$emit('confirmed', this.companyId, this.contactId, this.userManagesCompanyRoleId);
        },
        loadRoles() {
            let url;
            if (this.companyId > 0) {
                url = '/api/staffing/roles/company/' + this.companyId + "/true";
            } else {
                url = '/api/staffing/roles/contact/' + this.contactId + "/true";
            }

            this.$axios.get(url).then((response) => {
                this.roles = response.data;
            }).finally(() => {
                this.loaded = true;
            });
        },
    }
}
