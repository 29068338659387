

import FileUploadInput from "../../file-upload-input.vue";
import iriPreparation from "../../../mixins/iri-preparation";
import SaveButton from "../../save-button.vue";

export default {
    name: "UploadModal",
    components: {SaveButton, FileUploadInput},
    mixins: [iriPreparation],
    props: {
        company: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            fileTemplate: {
                title: null,
                excel: null,
                company: this.company['@id']
            },
            loading: false
        }
    },
    computed: {},
    watch: {},
    methods: {
        submitForm() {
            this.loading = true;
            this.$axios.post('/api/third_party_meeting_imports', this.fileTemplate)
                .then(response => {
                    this.$emit('import', response.data.id);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
