
import PitRequestForm from "../../components/pit-requests/pit-request-form.vue";

export default {
    name: "PitRequestModal",
    components: {
        PitRequestForm,
    },
    props: {
        company: {
            type: Object,
            default: null
        }
    }

}
