
import ResearchReportsTable from './research-reports-table'

export default {
    name: "ResearchReportsCard",
    components: {ResearchReportsTable},
    props: {
        userId: {
            type: Number,
            default: null
        },
        companyId: {
            type: Number,
            default: null,
        },
        companiesHash: {
            type: String,
            default: null,
        },
        pageSize: {
            type: Number,
            default: null,
        },
        showDownload: {
            type: Boolean,
            default: false,
        },
    },
}
