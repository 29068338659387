
import LoadingSpinner from "../../loading-spinner.vue";

export default {
    name: "AutosaveCellSelect",
    components: {LoadingSpinner},
    props: {
        initialValue: {
            // eslint-disable-next-line vue/require-prop-type-constructor
            type: String|Number,
            required: false,
            default: null
        },
        ownerId: {
            type: Number,
            required: true
        },
        attribute: {
            type: String,
            required: true
        },
        options: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            value: null,
            allowUpdates: false,
            loading: false,
        }
    },
    watch: {},
    async created() {
        // eslint-disable-next-line
        this.value = this.options.filter(x => x.value == this.initialValue)[0];
        await this.$nextTick();
        this.allowUpdates = true;
    },
    methods: {
        submitUpdate() {
            if (!this.allowUpdates) {
                return;
            }

            this.loading = true;
            this.$axios.patch(`/api/targeting_group_members/${this.ownerId}/update_attribute`, {
                attribute: this.attribute,
                value: this.value?.value
            }, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            }).catch(() => {
                this.$emit("error");
            }).finally(() => {
                this.loading = false;
            });
        }
    },
}
