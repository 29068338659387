

import SaveButton from "../../save-button.vue";
import fileDownload from "../../../mixins/file-download";

export default {
    name: "DownloadTargetingMemberGroupModal",
    components: {SaveButton},
    mixins: [fileDownload],
    props: {
        downloadQuery: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            sheets: {
                targetingGroupMembers: true,
                fundSummary: true
            },
            sheetLabels: {
                targetingGroupMembers: 'Targeting Group Members',
                fundSummary: 'Fund Summary'
            }
        }
    },
    methods: {
        submitForm() {
            const finalQuery = {
                ...this.downloadQuery,
                sheets: this.sheets
            };
            console.log(finalQuery)
            this.saveQueryAndExport(finalQuery, '/api/targeting_group_members/excel');
            this.$emit('hide-modal');
        }
    }
}
