


import TableActions from "../../table-actions.vue";

export default {
    name: "ImportHistoryModal",
    components: {TableActions},
    props: {
        company: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            tableUpdate: null,
        }
    },
    computed: {
        filters() {
            return {
                company: this.company?.id,
            }
        },
        config() {
            return {
                columns: [
                    {
                        headline: "Title",
                    },
                    {
                        headline: "Imported At"
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                    }
                ],
                search: true,
                pagination: 25,
                ajaxUrl: this.isUserWithRoleCorporateAccess ? '/api/third_party_meeting_imports/list' : '',
            }
        }
    },
    watch: {},
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', () => {

        });
    },
    methods: {
        deleteImport(id) {
            if (!confirm('Are you sure you want to delete this import? This will remove all meetings associated with this import.')) {
                return;
            }
            console.log('delete import');
            this.$axios.delete(`/api/third_party_meeting_imports/${id}`)
                .then(() => {
                    this.tableUpdate = new Date();
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Import Deleted",
                        message: "The third party meeting import has been deleted successfully.",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                        this.$emit('import-deleted');
                    })
                });
        },
        importExcel(id) {
            console.log('import');
            this.$emit('import', id);
        },
    }
}
