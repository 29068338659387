

import TableActions from "../../table-actions.vue";
import ContactName from "../../contacts/contact-name.vue";
import modals from "../../../mixins/modals";
import fileDownload from "../../../mixins/file-download";
import ImportHistoryModal from "./import-history-modal.vue";
import UploadModal from "./upload-modal.vue";
import ImportModal from "./import-modal.vue";
import EditThirdPartyMeetingModal from "./edit-third-party-meeting-modal.vue";

export default {
    name: "ThirdPartyMeetingsTable",
    components: {
        TableActions,
        ImportModal,
        UploadModal,
        ImportHistoryModal,
        ContactName,
        EditThirdPartyMeetingModal,
        // TableActions
    },
    mixins: [modals, fileDownload],
    props: {
        company: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            tableConfig: {
                columns: [
                    {
                        headline: "Date",
                    }, {
                        headline: "Participants",
                    }, {
                        headline: "Contact Types",
                    }, {
                        headline: "Import",
                        breakpoint: "md"
                    },
                    {
                        headline: "Comments",
                        breakpoint: "all"
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                    }
                ],
                search: true,
                pagination: 50,
                ajaxUrl: '/api/third_party_meetings/list',
            },
            importHistoryModalKey: "importHistoryModal",
            uploadModalKey: "uploadModal",
            importModalKey: "importModal",
            importIdToImport: null,
            modal: null,
            tableUpdate: null,
            deletingThirdPartyMeetingId: null,
            thirdPartyMeeting: null,
            thirdPartyMeetingModalKey: "editThirdPartyMeetingModal",
        }
    },
    computed: {
        filters() {
            return {
                company: this.company?.id,
            }
        }
    },
    watch: {},
    mounted() {

    },
    methods: {
        resetThirdPartyMeeting() {
            this.thirdPartyMeeting = null;
        },
        editThirdPartyMeeting(id) {
            if (id) {
                this.$axios.get("/api/third_party_meetings/" + id).then(response => {
                    this.thirdPartyMeetingModalKey = this.generateUUID();
                    this.thirdPartyMeeting = response.data;
                    this.$nextTick(() => {
                        this.modal = this.openModal(this.$refs.editThirdPartyMeetingModal);
                    });
                })
            } else {
                this.$axios.get("/api/third_party_meetings/template/" + this.company.id).then(response => {
                    this.thirdPartyMeetingModalKey = this.generateUUID();
                    this.thirdPartyMeeting = response.data;
                    this.$nextTick(() => {
                        this.modal = this.openModal(this.$refs.editThirdPartyMeetingModal);
                    });
                });
            }
        },

        async openImportHistoryModal() {
            this.importHistoryModalKey = this.generateUUID();
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.importHistoryModal);
        },
        async openUploadModal() {
            this.uploadModalKey = this.generateUUID();
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.uploadModal);
        },
        async openImportModal(id) {
            this.closeModal(this.modal);
            this.importIdToImport = id;
            this.importModalKey = this.generateUUID();
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.importModal);
        },
        importDone() {
            this.closeModal(this.modal);
            this.importIdToImport = null;
            this.importModalKey = this.generateUUID();
            this.tableUpdate = new Date();
        },
        downloadExcel() {
            const query = {
                filters: this.filters,
            }
            this.saveQueryAndExport(
                query,
                `/api/third_party_meetings/excel_export?hash=${this.companyHash}`
            );
        },

        deleteMeeting(id) {
            if(!confirm("Are you sure you want to delete this third party meeting?")) {
                return;
            }

            this.deletingThirdPartyMeetingId = id;
            this.$axios.delete("/api/third_party_meetings/" + id).then(async () => {
                this.tableUpdate = new Date();
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Third Party Meeting Deleted",
                    message: "The third party meeting has been deleted.",
                    id: toastId,
                });
                await this.$nextTick();
                this.toggleToast(toastId);
            })
                .finally(() => {
                    this.deletingThirdPartyMeetingId = null;
                });
        },

        onThirdPartyMeetingSaved() {
            if (this.modal) {
                this.closeModal(this.modal);
            }
            this.thirdPartyMeeting = null;
            this.tableUpdate = new Date();
        }
    }
}
