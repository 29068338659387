
import moment from "moment";
import TableActions from "../table-actions";
import LoadingSpinner from "../loading-spinner";
import optionButtonTooltip from "~/mixins/option-button-tooltip";

export default {
    name: "SECFilingsCard",
    components: {LoadingSpinner, TableActions},
    mixins: [optionButtonTooltip],
    props: {
        company: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            companyWorkingCopy: {},
            secFilings: [],
            rowsUpdated: moment().unix(),
            config: {
                columns: [
                    {
                        headline: "Date",
                        // sort: true
                    }, {
                        headline: "Type",
                        // sort: true
                    }, {
                        headline: "Description",
                        // sort: true
                    }, {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                    }
                ],
                pagination: 5
            },
            hideUnknown: false,
            loading: false,
        }
    },
    computed: {
        secFilingRows() {
            return this.secFilings.map(item => {
                return [
                    {
                        text: moment(item.fillingDate, ["YYYY-MM-DD", "YYYY-MM-DD HH:mm:ss"]).format("MM/DD/YYYY"),
                        sortValue: item.fillingDate
                    }, {
                        text: item.type,
                        tooltip: item.description,
                        sortValue: item.type
                    }, {
                        item,
                        type: "description",
                        text: item.description,
                        sortValue: item.description
                    }, {
                        item,
                        type: "links",
                        linkHash: this.createSimpleHash(item.link),
                        finalLinkHash: this.createSimpleHash(item.finalLink),
                    }
                ]
            });
        },
    },
    watch: {
        secFilings() {
            this.$nextTick(() => {
                this.rowsUpdated = moment().unix();
            })
        },
        rowsUpdated() {
            setTimeout(() => this.initializeOptionButtonTooltips('rows updated'), 250);
        },
    },
    mounted() {
        this.loadSECFilings();
        this.resetWorkingCopy();
    },
    methods: {
        onMouseEnterLink(id) {

            // eslint-disable-next-line no-new
            // new this.$bootstrap.Tooltip(document.getElementById(`cell-link-${id}`),{
            //     template:"<div class=\"tooltip\" role=\"tooltip\"><div class=\"tooltip-inner\"></div></div>"
            // });
        },
        loadSECFilings() {
            this.loading = true;
            this.$axios.get("/api/companies/stock_info/" + this.company.id + "/sec_filings")
                .then((response) => {
                    this.secFilings = response.data;
                }).finally(() => {
                    this.loading = false;

                })
        },
        resetWorkingCopy() {
            this.companyWorkingCopy = Object.assign({}, this.company);
        },
        downloadSECFilings() {
            window.open(
                this.$axios.defaults.baseURL + "/api/companies/excel_export/sec_filings/" + this.company.id +
                    "?" + this.buildQueryString({"hideUnknown": this.hideUnknown}),
                '_blank'
            ).focus();
        }
    }
}
