
import CurrencyChange from "../helpers/currency-change";
import PercentageChange from "../helpers/percentage-change";

export default {
    name: "StockInfo",
    components: {PercentageChange, CurrencyChange},
    props: {
        company: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            items: {},
            Up: false,
            change: null,
            currency: null,
            isLoading: false,
        }
    },
    computed: {
        firstColumn() {
            return Object.fromEntries(
                Object.entries(this.items).slice(0, 10)
            )
        },
        secondColumn() {
            return Object.fromEntries(
                Object.entries(this.items).slice(10, 20)
            )
        },
        isUp() {
            return this.change >= 0;
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.isLoading = true;
            this.$axios.get(`/api/companies/combined_stock_info/${this.company.id}`).then((response) => {
                let res = response.data.quote;

                this.change = res[0].change ?? null;

                const items = {
                    'Market Cap': res[0].hasOwnProperty('marketCap') ? this.nFormatter(res[0].marketCap) : 'n/a',
                    'Last': res[0].hasOwnProperty('price') ? res[0].price.toFixed(2) : 'n/a',
                    '$ Chg': res[0].hasOwnProperty('change') ? res[0].change.toFixed(2) : 'n/a',
                    '% Chg': res[0].hasOwnProperty('changesPercentage') ? res[0].changesPercentage.toFixed(2) : 'n/a',
                    'Shares Out': res[0].hasOwnProperty('sharesOutstanding') ? this.nFormatter(res[0].sharesOutstanding) : 'n/a',
                    'Volume': res[0].hasOwnProperty('volume') ? this.nFormatter(res[0].volume) : 'n/a',
                    'Open': res[0].hasOwnProperty('open') ? res[0].open.toFixed(2) : 'n/a',
                    'Close': res[0].hasOwnProperty('previousClose') ? res[0].previousClose.toFixed(2) : 'n/a',
                    'High': res[0].hasOwnProperty('dayHigh') ? res[0].dayHigh.toFixed(2) : 'n/a',
                    'Low': res[0].hasOwnProperty('dayLow') ? res[0].dayLow.toFixed(2) : 'n/a',
                    'Beta': 'n/a',
                    // ['Beta', res[0].beta],
                    'Year High': res[0].hasOwnProperty('yearHigh') ? res[0].yearHigh.toFixed(2) : 'n/a',
                    'Year Low': res[0].hasOwnProperty('yearLow') ? res[0].yearLow.toFixed(2) : 'n/a',


                    // ['Dividend', res[0].dividend],
                    // ['Div. Pay Date', res[0].paymentDate],
                    // ['Ex-Div date', res[0].date],


                    'PB Ratio': 'n/a',
                    // ['PB Ratio', res[0].pbRatio],
                    'PE': res[0].hasOwnProperty('pe') && res[0].pe !== null ? res[0].pe.toFixed(2) : 'n/a',
                    'EPS': res[0].hasOwnProperty('eps') && res[0].eps !== null ? res[0].eps.toFixed(2) : 'n/a',
                    'Exchange': 'n/a',
                    'Div. Pay Date': 'n/a',
                    'Ex-Div Date': 'n/a',
                    'Dividend': 'n/a',


                    // ['Exchange', res[0].exchangeShortName],
                }

                res = response.data.profile;
                items.Beta = (res[0] && res[0].beta) ? res[0].beta.toFixed(2) : 'n/a';
                items.Exchange = res[0] ? res[0].exchangeShortName : 'n/a';
                this.currency = res[0] ? res[0].currency : '';

                res = response.data['key-metrics'];
                items['PB Ratio'] = (res[0] && res[0].pbRatio) ? res[0].pbRatio.toFixed(2) : 'n/a';

                res = response.data['company-outlook'];
                if (res && res.stockDividend && res.stockDividend.length > 0) {
                    items.Dividend = res.stockDividend[0].dividend.toFixed(2);
                    items['Div. Pay Date'] = this.formatDate(res.stockDividend[0].paymentDate) !== "Invalid date" ? this.formatDate(res.stockDividend[0].paymentDate) : "n/a";
                    items['Ex-Div date'] = this.formatDate(res.stockDividend[0].date) !== "Invalid date" ? this.formatDate(res.stockDividend[0].date) : "n/a";
                } else {
                    items.Dividend = 'n/a';
                    items['Div. Pay Date'] = 'n/a';
                    items['Ex-Div date'] = 'n/a';
                }

                this.items = items;
            }).finally(() => {
                this.isLoading = false;
            });
        },
    },
}
