

import {mapActions, mapGetters} from "vuex";
import SaveButton from "../save-button";
import LoadingSpinner from "../loading-spinner";
import fileDownload from "../../mixins/file-download";
import modals from "../../mixins/modals";


export default {
    name: "InteractionBulkOperationModal",
    components: {LoadingSpinner, SaveButton,},
    mixins: [fileDownload, modals],
    props: {
        filters: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    data() {
        return {
            operation: "download",
            running: false,
            loading: false,
            bulkResearchReports: [],
            ResearchReportsLoading: [],
            contact: null,
        };
    },
    computed: {
        ...mapGetters({
            bulkResearchReportFiltersHash: 'app/bulkResearchReportFiltersHash',
        }),
        bulkOptions() {
            return [
                {value: 'download', label: 'Download'},
                {value: 'copy_links', label: 'Copy Research Report Links (Clipboard)'},
                {value: 'share_links', label: 'Share Research Report Links (Create Email Draft)'},
            ]
        },
    },
    watch: {
        operation() {
            // Add stuff if needed
        }
    },
    mounted() {
        this.loadResearchReports
            ();
    },
    methods: {
        ...mapActions({
            setBulkResearchReportFiltersHash: "app/setBulkResearchReportFiltersHash",
        }),
        submitForm() {
            switch (this.operation) {
                case "download":
                    this.downloadBulk();
                    break;
                case "copy_links":
                    this.copyLinks();
                    break;
                case "share_links":
                    this.prepareSharingMail();
                    break;
                default:
                    console.error(`Operation ${this.operation} is not allowed.`);
            }
        },
        async loadResearchReports
            () {
            this.loading = true;
            this.ResearchReportsLoading = true;
            const responseFilters = await this.$axios.post("/api/research_reports/save_bulk_filters", {filters: this.filters});
            this.setBulkResearchReportFiltersHash(responseFilters.data.hash);

            this.$axios.get("/api/research_reports/bulk_research_reports", {
                params: {
                    hash: this.bulkResearchReportFiltersHash
                }
            }).then(response => {
                this.bulkResearchReports = response.data["hydra:member"];
            }).finally(() => {
                this.loading = false;
            });
        },
        /* submitted() {
            this.$emit("submitted");
        }, */
        downloadBulk() {
            const hash = this.bulkResearchReportFiltersHash;

            const url = `${this.$axios.defaults.baseURL}/api/research_reports/bulk_download/${hash}`;
            window.open(url, '_blank').focus();
        },
        copyLinks() {
            this.running = true;
            const hash = this.bulkResearchReportFiltersHash;

            this.$axios.get(`/api/research_reports/bulk_links/${hash}`, {}).then(response => {
                this.copyTextToClipboard(response.data.links, "Links are copied!").catch(err => {
                    console.error(err);
                });

            }).finally(() => {
                this.running = false;
                this.$emit("submitted", false);
            });
        },
        prepareSharingMail() {
            this.$axios.post(`/api/email/share_report_links/${this.bulkResearchReportFiltersHash}`, {
                contactId: this.contact ? this.contact.id : null,
            })
                .then((response) => {
                    const draftId = response.data.draftId;
                    this.$router.push(`/emails/${draftId}`);
                });
        },
    }
}
