

import SaveButton from "../../save-button.vue";
import iriPreparation from "../../../mixins/iri-preparation";

export default {
    name: "AddTargetingGroupModal",
    components: {SaveButton},
    mixins: [iriPreparation],
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            title: null,
            contactGroupsToAdd: [],
            contactsToAdd: [],
            companyGroupsToAdd: [],
            companiesToAdd: [],
            companyComment: "",
        }
    },
    methods: {
        submitForm() {
            this.loading = true;

            this.$axios.post("/api/targeting_groups", {
                title: this.title,
                companyId: this.company.id,
                contactGroupIds: this.contactGroupsToAdd.map(group => group.id),
                contactIds: this.contactsToAdd.map(contact => contact.id),
                companyGroupIds: this.companyGroupsToAdd.map(group => group.id),
                companyIds: this.companiesToAdd.map(company => company.id),
                companyComment: this.companyComment || null,
            }).then(response => {
                this.loading = false;
                this.$emit("close");
                // open new tab with group
                window.open(`/engine/companies/targeting/groups/${response.data.id}`, '_blank');
            }).finally(() => {
                this.loading = false;
                this.title = null;
            });

            this.$emit("submit");
        }
    }
}
