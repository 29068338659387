
import StockInfo from "./stock-info.vue";

export default {
    name: "StockInfoCard",
    components: {StockInfo},
    props: {
        company: {
            type: Object,
            required: true,
        }
    }
}
