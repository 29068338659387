

import modals from "../../../mixins/modals";
import ThirdPartyMeetingsTable from "./third-party-meetings-table.vue";

export default {
    name: "ThirdPartyMeetingsCard",
    components: {ThirdPartyMeetingsTable},
    mixins: [modals],
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
        }
    },
    computed: {
    },
    methods: {

    }
}
